<template>
  <v-row style="margin-top: 20px">
    <v-col>
      <v-btn
        v-if="isStockManager"
        class="primary"
        block
        to="/parts/create"
      >
        {{ $t('c.button')}}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { rolesIds } from '@/roles'

export default {
  name: 'CreatePartButton',
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    isStockManager () {
      return this.user.user_role_id === rolesIds.stockManager
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          button: 'Create Part',
        },
      },
      it: {
        c: {
          button: 'Creare Parte',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
