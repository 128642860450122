<template>
  <div v-if="user">
    <entity-parts-list-wrapper
      v-if="isEntityAdmin"
      :entity-id="user.entity_id"
    />
    <parts-list-wrapper
      v-else
    />
  </div>
</template>

<script>
import EntityPartsListWrapper from '@/views/dashboard/pages/PartsIndex/components/EntityPartsListWrapper'
import PartsListWrapper from '@/views/dashboard/pages/PartsIndex/components/PartsListWrapper'

export default {
  name: 'PartsIndex',
  components: { PartsListWrapper, EntityPartsListWrapper },
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    isEntityAdmin () {
      if (!this.user) {
        return false
      }
      return this.user.user_role.name === 'entity_admin'
    },
  },
}
</script>

<style scoped>

</style>
